import React from "react"
import Sidebar from "../../components/holdersPage/sidebar"
import styled from "@emotion/styled"
import Order from "../../components/common/order"
import { Helmet } from "react-helmet"

const Heading = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1.1rem 0;
  color: ${props => props.theme.colors.activeLink};
`

const ConditionHeading = styled.div`
  font-size: 1.2rem;
  margin: 2rem 0;
  font-weight: 500;
`
const questions = [
  {
    q: "Обращение субъекта РФ в ППК Фонд",
    a: (
      <div>
        Субъект РФ обращается в ППК Фонд с ходатайством о восстановлении прав
        дольщиков в отношении домов, включенных в Единый реестр проблемных
        объектов, расположенных на территории субъекта РФ.
      </div>
    ),
  },
  {
    q: "Банкротство застройщика",
    a: (
      <div>
        <p>
          ППК Фонд инициирует процедуру банкротства застройщика либо, если такая
          процедура уже была начата, вступает в дело о банкротстве. Арбитражный
          управляющий, заявленный ППК Фонд, в течение 3 месяцев со дня
          вступления в силу решения Арбитражного суда об открытии в отношении
          должника (застройщика) конкурсного производства:
        </p>
        <ol>
          <li>Проводит инвентаризацию имущества застройщика</li>
          <li>
            Определяет стоимость земельных участков (прав на земельные участки),
            предназначенных для размещения объектов незавершенного строительства
          </li>
          <li>
            Формирует реестр требований всех кредиторов, в том числе участников
            строительства
          </li>
          <li>
            Определяет размер денежных средств, необходимых для погашения
            требований кредиторов
          </li>
        </ol>
      </div>
    ),
  },
  {
    q: "ППК Фонд организует обследование строящегося жилого дома",
    a: (
      <div>
        <p>
          ППК Фонд в течение 6 месяцев со дня вступления в силу решения
          Арбитражного суда об открытии в отношении должника (застройщика)
          конкурсного производства:
        </p>
        <ol>
          <li>
            Организует обследование технического состояния объекта
            незавершенного строительства
          </li>
          <li>
            Определяет степень готовности объекта незавершенного строительства
          </li>
          <li>
            Определяет размер денежных средств и сроки, необходимые для
            завершения строительства проблемного объекта
          </li>
          <li>
            Определяет рыночную стоимость 1 кв. метра жилого помещения объекта
            незавершенного строительства
          </li>
          <li>
            Определяет размер предполагаемого возмещения гражданам - участникам
            строительства
          </li>
        </ol>
      </div>
    ),
  },
  {
    q: "Заключение соглашения о финансировании",
    a: (
      <div>
        ППК Фонд заключает с субъектом РФ соглашение о финансировании, в
        соответствии с которым субъект РФ предоставляет ППК Фонд субсидию в виде
        имущественного взноса из бюджета субъекта РФ для финансирования
        мероприятий по завершению строительства или выплаты компенсаций
        участникам строительства.
      </div>
    ),
  },
  {
    q: "Принятие ППК Фонд решения",
    a: (
      <div>
        ППК Фонд сравнивает сумму, необходимую для завершения строительства, с
        суммой возмещения дольщикам и принимает решение о целесообразности
        финансирования завершения строительства или выплаты компенсации
        дольщикам. Не позднее 3 рабочих дней с даты решения, ППК размещает
        соответствующее сообщение на своём официальном сайте.
      </div>
    ),
  },
]

const secondQuestions = [
  {
    q:
      "Заключение ППК Фонд с фондом субъекта РФ соглашения о финансировании мероприятий по завершению строительства",
    a: (
      <div>
        По факту перехода к фонду субъекта РФ земельного участка с находящимися
        на нем неотделимыми улучшениями и обязательств застройщика ППК Фонд
        заключает с фондом субъекта РФ соглашение о финансировании мероприятий
        по завершению строительства
      </div>
    ),
  },
  {
    q: "Передача фонду субъекта РФ имущества и обязательств застройщика",
    a: (
      <div>
        После вынесения определения Арбитражным судом о передаче фонду субъекта
        РФ имущества и обязательств застройщика конкурсный управляющий заключает
        с фондом субъекта РФ договор (договоры) передачи земельного участка с
        находящимися на нем неотделимыми улучшениями и обязательств застройщика
      </div>
    ),
  },
  {
    q:
      "Обращение фонда субъекта РФ с заявлением о намерении стать приобретателем имущества и обязательств застройщика",
    a: (
      <div>
        Фонд субъекта РФ направляет в Арбитражный суд, конкурсному управляющему
        и в уполномоченный Правительством РФ федеральный орган исполнительной
        власти, осуществляющий государственное регулирование в области долевого
        строительства (Минстрой РФ), заявление о намерении стать приобретателем
        имущества и обязательств застройщика
      </div>
    ),
  },
  {
    q: "Реализация комплекса мероприятий по завершению строительства",
    a: (
      <div>
        Фонд субъекта РФ осуществляет комплекс мероприятий по завершению
        строительства объекта незавершенного строительства. Срок завершения
        строительства и передачи квартир не может превышать 3 года со дня
        вынесения Арбитражным судом определения о передаче фонду субъекта РФ
        имущества и обязательств застройщика
      </div>
    ),
  },
  {
    q: "Передача объектов долевого строительства",
    a: (
      <div>
        Передача объекта долевого строительства осуществляется в порядке,
        предусмотренном Законом № 214-ФЗ. По факту завершения строительства фонд
        субъекта РФ не позднее чем за 1 месяц до даты начала передачи объекта
        долевого строительства обязан направить дольщику сообщение о завершении
        строительства
      </div>
    ),
  },
]

const thirdQuestions = [
  {
    q: "Обращение дольщиков с заявлением в ППК Фонд на выплату возмещения",
    a: (
      <div>
        <p>
          Федеральный фондом самостоятельно осуществляет выплаты гражданам (без
          привлечения и участия Регионального фонда ПК).
        </p>
        <p>
          Информация о начале производства выплат по конкретному порядке
          проблемному объекту и порядку предоставления выплат размещается во
          вкладке "новости" на официальном сайте{" "}
          <a href="https://фонд214.рф" target="_blank" rel="noreferrer">
            фонд214.рф
          </a>
        </p>
        <p>
          Прием граждан осуществляется по предварительной записи. Записаться на
          удобные дату и время для подачи заявлений можно на официальном сайте
          Фонда по адресу:{" "}
          <a href="https://фонд214.рф" target="_blank" rel="noreferrer">
            фонд214.рф
          </a>
          , или по телефону горячей линии:{" "}
          <a href="tel:88007007214">8-800 7007-214</a>.
        </p>
        <p>
          Выплаты гражданам осуществляет уполномоченный в сфере жилищного
          строительства банк-агент «ДОМ.РФ», который рассматривает документы и
          производит выплату в течение 10 рабочих дней с даты подачи полного
          комплекта документов.
        </p>
        <p>
          Компенсация рассчитывается исходя из рыночной стоимости квадратного
          метра. Размер выплаты устанавливается индивидуально для каждого
          дольщика с учетом стоимости кв. метра равнозначных жилых помещений на
          первичном рынке на момент выплаты возмещения (определяется на
          основании отчета независимого оценщика, привлеченного Фондом), площади
          квартиры, исполненной части обязательства дольщика по оплате.
        </p>
        <p>
          Площадь квартиры, за которую можно получить компенсацию, суммарно не
          должна превышать 120 кв. метров.
        </p>
        <p>
          Сумма компенсации не может быть меньше, чем сумма, оплаченная по
          договору участия в долевом строительстве.
        </p>
        <p>
          Если при оплате договора долевого участия использовались средства
          материнского капитала, то сумма, которая была оплачена за счет
          материнского капитала, Фондом дольщиков возвращается в Пенсионный
          фонд. При этом у гражданина возникает право повторного использования
          материнского капитала на цели, предусмотренные федеральным законом.
        </p>
        <p>
          При возникновении вопросов - необходимо обращаться по телефону горячей
          линии: <a href="tel:88007007214">8-800 7007-214</a>
        </p>
      </div>
    ),
  },
  {
    q: "Проверка ППК Фонд полученного заявления на выплату возмещения",
    a: (
      <div>
        По факту получения заявления на выплату возмещения и приложенных к нему
        документов ППК Фонд осуществляет проверку представленных документов на
        предмет обоснованности и комплектности.
      </div>
    ),
  },
  {
    q: "Выплата ППК Фонд возмещения",
    a: (
      <div>
        <p>
          Выплата возмещения производится в срок не позднее 10 рабочих дней с
          даты получения заявления на выплату возмещения и приложенных к нему
          документов. Расчет размера возмещения осуществляется в соответствии с
          Правилами, утвержденными постановлением Правительства РФ от 7 октября
          2017 г. № 1233.
        </p>
        <p>
          Возмещение выплачивается ППК Фонд через уполномоченные банки (Банк.
          Дом РФ) напрямую гражданину - участнику строительства без участия
          региональных властей и регионального фонда (Фонд ПК). Порядок и
          правила предоставления выплат размещен на сайте ППК Фонд (
          <a
            href="https://xn--214-mdd8bf5b.xn--p1ai/"
            target="_blank"
            rel="noreferrer"
          >
            фонд214.рф
          </a>
          )
        </p>
      </div>
    ),
  },
]

const About = props => {
  return (
    <Sidebar>
      <Helmet>
        <title>Алгоритм восстановления прав дольщиков</title>
        <meta
          name="description"
          content={`Алгоритм восстановления прав дольщиков, Фонд Защиты Прав Граждан участников строительства в ПК`}
        />
      </Helmet>
      <h1>Алгоритм восстановления прав дольщиков</h1>
      <div>
        <Order list={questions} startingIndex={1} />
      </div>
      <ConditionHeading>
        Далее порядок действий зависит от принятого Фондом решения:
      </ConditionHeading>
      <Heading>Решение о завершении строительства</Heading>
      <div>
        <Order list={secondQuestions} startingIndex={6} />
      </div>
      <Heading>Решение о получении денежных выплат по ДДУ</Heading>
      <div>
        <Order list={thirdQuestions} startingIndex={6} />
      </div>
    </Sidebar>
  )
}

export default About
